import Base from '@/api/entity/Base'

export default class User extends Base {
    nickName: string | undefined
    avatar: string | undefined
    static domain: String = '/system/user'

    constructor() {
        super(User.domain)
    }
    static getInstance = (object = {}) => {
        const bean = Object.assign(new User(), object)
        return bean
    }
}