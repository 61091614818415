import axiosInstance from '~/plusgin/axios';
// import qs from 'qs'

export default abstract class Base {
  id: Number|undefined
  domain: String
  public static axios = (config:any) => axiosInstance.request({ ...config, url: config.url.replace(/(?<=:\/.*)(\/\/*)/g, '/') })
  constructor(domain: String) {
    this.domain = domain
  }

  static ajaxServerURL = () => import.meta.env.VITE_APP_BASE_API

  deleteById = (ids: any) => Base.axios({
    method: 'delete',
    url: `${Base.ajaxServerURL()}/${this.domain}/delete`,
    data: (!(ids instanceof Array)) ? [parseInt(ids, 10)] : ids
  })

  delete = (id: any) => Base.axios({
    method: 'delete',
    url: `${Base.ajaxServerURL()}/${this.domain}/${id || this.id}`
  })

  get = (id: Number) => Base.axios({
    method: 'get',
    url: `${Base.ajaxServerURL()}/${this.domain}/${id}`
  })

  getList = (data: any = this, page: Number, size: Number) => {
    let url = `${Base.ajaxServerURL()}/${this.domain}/`
    if (size !== undefined) {
      url += `page/${page}/size/${size}`
    }
    return Base.axios({
      method: 'post',
      url,
      headers: { 'Content-Type': 'application/json' },
      data: data || {}
    })
  }

  create = () => Base.axios({
    method: 'put',
    url: `${Base.ajaxServerURL()}/${this.domain}`,
    headers: { 'Content-Type': 'application/json' },
    data: this
  })

  update = (data = this) => Base.axios({
    method: 'put',
    url: `${Base.ajaxServerURL()}/${this.domain}${this.id ? (`/${this.id}`) : ''}`,
    data
  })
}